import React from "react"
import Header from "../components/header"
import Heading from "../components/heading"
import Nav from "../components/nav"
import Footer from "../components/footer"
import styles from "../static/about.module.css"
import headshot from "../static/coby2.jpg"
import harvard from "../static/harvard-shield.png"
import bulb from "../static/ea.png"
import vertex from "../static/vertex.png"

var boxOpen = false
var b1 = false
var b2 = false
var b3 = false

class About extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
      box: 'none',
      box1: 'none',
      box2: 'none',
      box3: 'none'
    };
  }

 
render() {
	return (
  	<div>
	    <Header>
	  		<Heading title='About Me' href='/' linkText='Home' />
	  		<Nav />
	  	</Header>
	  	<div className={styles.aboutme}>
	  			<img className={styles.pic} src={headshot} alt="" />
	  			<p className={styles.descript}>
	  				Hi - I'm Coby. Aside from working on technical projects, I'm an avid reader, surfer, soccer player, and guitarist. Currently I volunteer at
	  				an after-school program for kids and TA for an introductory Computer Science class. In my downtime, I love playing games with friends
	  				and doing yoga (though not at the same time).
	  			</p>
	  	</div>
	  	<div className={styles.aboutmemore}>
	  		<div className={styles.subpart}>
	  			<img className={styles.subpic} src={harvard} alt="Harvard Shield"/>
	  			<p className={styles.listitem}>I'm currently a second semester sophomore at Harvard studying Computer Science.</p>
	  		</div>
	  		<div className={styles.subpart}>
	  			<img className={styles.subpic} src={bulb} alt="Effective Altruism"/>
	  			<p className={styles.listitem}>Recently I've become interested in a movement known as Effective Altruism.</p>
	  		</div>
	  		<div className={styles.subpart}>
	  			<img className={styles.subpic} src={vertex} id={styles.vertex} alt="Vertex Software Corporation"/>
	  			<p className={styles.listitem}>This summer I worked as an SWE intern at Vertex Software Corporation.</p>
	  		</div>
	  	</div>
	  	<Footer></Footer>
	</div>


  );
}
}

export default About
